@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

:root{
  --color-white: #fff;
  --heading-color: #23201F;
  --text-color: #353332;
  --theme-color: #FE5858;

  --hero-heading-font: 64px;
  --heading-font: 56px;
  --text-font: 22px;
}

/* Custom Scroll Bar */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
background-color: var(--theme-color);
outline: 1px solid var(--color-white);
}

.main_conatiner{
  position: absolute;
  top: 100px;
  width: 100%;
}

.btn, .btn1{
  border: none;
  outline: none !important;
  padding: 0.5em 1em;
  border-radius: 50px;
  width: max-content;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.btn{
  background-color: var(--theme-color);
  color: var(--color-white);
}

.btn1{
  border: 1px solid #111;
  background-color: var(--color-white);
  color: var(--text-color);
}

.section{
  padding: 3.75em 7.5em;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  gap: 1.3em;
}

.home_container, .help_section{
  align-items: center;
  text-align: center;
}

.hero_heading{
  font-size: var(--hero-heading-font);
  color: var(--heading-color);
}

.heading{
  font-size: var(--heading-font);
  color: var(--heading-color);
}

.text{
  font-size: var(--text-font);
  color: var(--text-color);
  line-height: 30px;
}

.link{
  color: var(--text-color);
}

.btn_link{
  color: var(--color-white);
}

.react-multiple-carousel__arrow{
  z-index: 100 !important;
}

@media(max-width:1250px){
  
}
@media(max-width:1150px){
  .section{
    padding: 3.75em 5.5em;
  }
}
@media(max-width:950px){
  .section{
    padding: 3.75em;
  }
  .container{
    flex-direction: column;
    gap: 1em;
  }

  .hero_heading{
    font-size: 36px;
  }
  
  .heading{
    font-size: 28px;
  }
  
  .text{
    font-size: 18px;
  }
}
@media(max-width:750px){

  .section{
    padding: 3em 2em;
  }
}
@media(max-width:550px){
}