.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
  z-index: 1000;
  padding: 0.4em 7.5em;
}

.nav_logo {
  width: 80px;
}

.navbar_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.links_container,
.nav_navlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.nav_links {
  color: #111;
  font-size: 17px;
  font-weight: 500;
}

.nav_btn {
  border: none;
  background: #ffae00;
  padding: 0.7em 1.6em;
  font-size: 1em;
  border-radius: 50px;
  cursor: pointer;
}

.nav_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8em;
}

.nav_dropdown {
  cursor: pointer;
  position: relative;
}

.nav_dropdown i {
  display: none;
}

.dropdown_content {
  display: block;
  position: absolute;
  top: 40px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: 0.3s ease-in-out;
  width: max-content;
}

.dropdown_content li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.dropdown_content li:hover {
  background: #d2d2d2;
}

.menu-icon {
  display: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.menu-icon .bar {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  margin-top: 0;
  transition: 0.4s;
  border-radius: 50px;
}

.nav_link_img {
  display: none;
}

.lang_btn{
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8em;
}

@media (max-width: 1150px) {
  .navbar {
    padding: 0 7em;
  }
}

@media (max-width: 950px) {
  .navbar {
    padding: 0 6em;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 4em;
  }

  .menu-icon {
    display: block;
  }

  .menu-icon.open .bar:first-child {
    transform: rotate(-45deg) translate(-7px, 6px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:last-child {
    transform: rotate(45deg) translate(-6px, -6px);
  }

  .nav_navlist {
    flex-direction: column;
    position: absolute;
    top: 91px;
    right: -100%;
    width: 50%;
    height: calc(100vh - 70px);
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5em;
    transition: right 0.5s ease-in-out;
  }

  .nav_navlist.show {
    right: 0;
  }

  .nav_links {
    display: flex;
    align-items: center;
    gap: 0.8em;
    padding: 0.8em;
  }

  .nav_link_img {
    display: flex;
  }

}

@media (max-width: 550px) {
  .navbar {
    padding: 0 2em;
    align-items: center;
  }

  .nav_logo {
    width: 70px;
  }

  .navbar_nav {
    padding: 0;
  }

  .menu-icon .bar:nth-child(3){
    margin: 0;
  }

  .nav_btn {
    display: none;
  }

  .nav_navlist {
    width: 70%;
  }

  .nav_dropdown i {
    margin-left: 2em;
  }
}

@media(max-width: 380px){
  .navbar{
    padding: 0 1.5em;
  }
}