.footer_section{
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    padding-bottom: 1.2em;
    background-color: #F4F4F4;
}

.footer{
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 3em;
}

.company_logo{
    width: 150px;
}

.contact_detail{
    display: flex;
    justify-content: flex-start;
    gap: 0.4em;
}

.btn_container{
    display: flex;
    align-items: center;
    gap: 2em;
}

.location_container, .contact_container{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.copyright_text{
    text-align: center;
}

@media(max-width:1250px){
  
}
@media(max-width:1150px){
.footer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
}
@media(max-width:950px){
    .footer{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}
@media(max-width:750px){

}
@media(max-width:550px){
    .footer{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }

    .btn_container{
        flex-direction: column;
        gap: 1em;
        justify-content: flex-start;
        align-items: start;
    }
}