.product_container {
  gap: 3em;
}

.product_try-container,
.product_overview {
  gap: 5em;
}

.product_overview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.try_img {
  width: 400px;
}

.product_video {
  width: 550px;
  border: 1px solid #111;
  border-radius: 15px;
}

@media (max-width: 1250px) {
}
@media (max-width: 1150px) {
}
@media (max-width: 950px) {
  .product_video {
    width: 100%;
  }

  .product_overview {
    flex-direction: column;
    gap: 1.5em;
  }

  .product_overview:nth-child(even) {
    flex-direction: column-reverse;
  }
}
@media (max-width: 750px) {
  .try_img {
    width: 100%;
  }
}
@media (max-width: 550px) {
}
