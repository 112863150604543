.usecase_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5em;
  text-align: center;
  overflow-x: hidden !important;
}

.slider_container {
  width: 100%;
  position: relative;
}

.slide {
  display: flex;
  gap: 3em;
  gap: 0;
  flex-direction: column;
  width: 350px;
}

.slide_video {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.slide_content,
.slide_content2 {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5em;
}

.slide_content {
  background: var(--theme-color);
  color: var(--color-white);
}

.slide_content2 {
  background: var(--color-white);
  color: #111;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.content_text {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  justify-content: start;
}

.content_list {
  margin-left: 1.2em;
  list-style: disc;
  text-align: start;
}

.carousel-item {
  margin-left: 1.5rem !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
  margin-bottom: 4.5em;
}

.react-multiple-carousel__arrow--right {
  right: 0em !important;
  margin-bottom: 4.5em;
}

.react-multi-carousel-dot-list {
  display: none !important;
}

@media (min-width: 1280px) {
  .react-multi-carousel-item {
    width: 370px !important;
  }
}

@media (max-width: 1250px) {
  .slide .slide_video,
  .slide_content,
  .slide_content2 {
    width: 280px;
  }

  .carousel-item {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 1150px) {
}
@media (max-width: 950px) {
  .slide {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .slide .slide_video,
  .slide_content,
  .slide_content2 {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .slide_content,
  .slide_content2 {
    padding: 1em;
  }
}
